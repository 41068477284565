/* @import url('https://fonts.googleapis.com/css2?family=BIZ+UDPGothic&display=optional'); */

/*rankScoreList*/
.rankScoreList {
    padding: 1px;
}
.rankScoreTxtBox {
    position: absolute;
    top: 4px;
    left: 3px;
 }
.rankScoreTxt {
    padding: 8px 1.5em;
    color: #FFF;
    font-style: normal;
    font-size: min(3.5vw,10px) !important;
    letter-spacing: -.05em;
    background-color: rgba(0, 0, 0, .4);
    border-radius: 15px;
}
.crownIconArea {
    display: none;
}
.rankListArea {
    padding-top: 15vh;
}
.rankListTxt {
    color: #FFF;
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    margin-bottom: 3vh;
}
.rankListTxt button {
    display: inline-block;
    margin: 0 1em;
    padding: 3px 1em;
    font-size: min(3vw,14px);
    color: #fff;
}
.highestTxt {
    position: relative;
}
.highestTxt:before {
    position: absolute;
    content: "↑";
    top: 0px;
    left: 0;
    display: inline-block;
    font-size: min(3vw,14px) !important;
    color: #fff;
}

.highestTxt.active::before {
    color: #fff;
}

.highestTxt:hover::before {
    color: #fff;
}

.lowestTxt {
    position: relative;
}
.lowestTxt:after {
    position: absolute;
    content: "↓";
    top: 0px;
    left: 0;
    font-size: min(3vw,14px) !important;
    color: #fff;
}

.lowestTxt:hover::after {
    color: #fff;
}

.lowestTxt.active::after {
    color: #fff; 
}
